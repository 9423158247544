<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-grow-1">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div class="symbol symbol-100px symbol-fixed position-relative">
            <img src="media/avatars/150-2.jpg" alt="image" />
            <div
              class="
                position-absolute
                translate-middle
                bottom-0
                start-100
                mb-6
                bg-success
                rounded-circle
                border border-4 border-white
                h-20px
                w-20px
              "
            ></div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >Rakib Hasan</a
                >
                <a href="#">
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <inline-svg src="media/icons/duotune/general/gen026.svg" />
                  </span>
                </a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="media/icons/duotune/communication/com006.svg"
                    />
                  </span>
                  CEO & Founder
                </a>
                <!-- <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="media/icons/duotune/general/gen018.svg" />
                  </span>
                  SF, Bay Area
                </a>
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="media/icons/duotune/communication/com011.svg"
                    />
                  </span>
                  max@kt.com
                </a> -->
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->
            <!--end::Actions-->
          </div>
          <!--end::Title-->
        </div>
        <div class="flex-grow-1">
          <div class="d-flex flex-wrap fw-bold fs-6">
            <div class="d-flex align-items-center text-hover-primary me-5 mb-2">
              <span class="fw-bold d-block"
                ><i class="fa fa-envelope mr-2"></i></span
              >Email : rokib@gmail.com
            </div>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6">
            <div class="d-flex align-items-center text-hover-primary me-5 mb-2">
              <span class="fw-bold d-block"
                ><i class="fa fa-phone mr-2"></i></span
              >Contact : 01999111222
            </div>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6">
            <div class="d-flex align-items-center text-hover-primary me-5 mb-2">
              <span class="fw-bold d-block"
                ><i class="fa fa-burn mr-2"></i
              ></span>
              Blood Group :
            </div>
          </div>
        </div>

        <!--end::Info-->
      </div>
      <!--end::Details-->
    </div>
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6"></label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <!--begin::Image input-->
          <div
            class="image-input image-input-outline"
            data-kt-image-input="true"
            style="background-image: url(media/avatars/blank.png)"
          >
            <!--begin::Preview existing avatar-->
            <div
              class="image-input-wrapper w-125px h-125px"
              :style="`background-image: url()`"
            ></div>
            <!--end::Preview existing avatar-->

            <!--begin::Label-->
            <label
              class="
                btn btn-icon btn-circle btn-active-color-primary
                w-25px
                h-25px
                bg-white
                shadow
              "
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              title="Change avatar"
            >
              <i class="bi bi-pencil-fill fs-7"></i>

              <!--begin::Inputs-->
              <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
              <input type="hidden" name="avatar_remove" />
              <!--end::Inputs-->
            </label>
            <!--end::Label-->

            <!--begin::Remove-->
            <span
              class="
                btn btn-icon btn-circle btn-active-color-primary
                w-25px
                h-25px
                bg-white
                shadow
              "
              data-kt-image-input-action="remove"
              data-bs-toggle="tooltip"
              @click="removeImage()"
              title="Remove avatar"
            >
              <i class="bi bi-x fs-2"></i>
            </span>
            <!--end::Remove-->
          </div>
          <!--end::Image input-->

          <!--begin::Hint-->
          <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
          <!--end::Hint-->
        </div>

        <!--end::Col-->
      </div>
      <div class="row mb-6">
        <div class="col-md-10 text-center">
          <button type="button" class="btn btn-primary btn-sm">Upload</button>
        </div>
      </div>
    </div>
  </div>
</template>
